import React from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import i18n from "../../packs/i18n";
import { withTranslation } from 'react-i18next';
import TopAdminModal from "./TopAdmin";
import DataTransferModal from "./DataTransfer";
import API from '../api';
import FlashMessage from 'react-flash-message'
class NavMenu extends React.Component {
    
    constructor(props) {
        super(props)
        let _act_key = 'users';
        if (props && props.location && props.location.pathname){
            let splitArray = props.location.pathname.split('/admin')
            let title = 'i3LEARNHUB | Admin';
            title += this.capitalizeWords(splitArray[1].split('/')).join(' | ')
            document.title = title
            if(props.location.pathname.includes('admin/user') || props.location.pathname.includes('admin/institution'))
            {
                _act_key = 'users'
            }
            else if(props.location.pathname.includes('admin/distributor') || props.location.pathname.includes('admin/reseller')){
                _act_key = 'distributors'
            }
            else if(props.location.pathname.includes('admin/invitations')){
                _act_key = 'invitations'
            }
            else if(props.location.pathname.includes('admin/payments')){
                _act_key = 'payments'
            }
            else if(props.location.pathname.includes('admin/vouchers')){
                _act_key = 'vouchers'
            }
            else if(props.location.pathname.includes('admin/subscription_reminder') || props.location.pathname.includes('admin/email') || props.location.pathname.includes('admin/manage_pages') || props.location.pathname.includes('admin/oauth') || props.location.pathname.includes('admin/libraries') || props.location.pathname.includes('admin/activelearning') || props.location.pathname.includes('admin/keyboards') || props.location.pathname.includes('admin/preferences') || props.location.pathname.includes('admin/educationallinks') || props.location.pathname.includes('/admin/data_transfer')){
                _act_key = 'settings'
            }
            else if(props.location.pathname.includes('admin/search')){
                _act_key = ''
            }
        }
        this.state = {
            showTopAdmin: false,
            showDataTransfer: false,
            activeKey: _act_key,
            top_admin_email: props.top_admin_email,
            isReportUploaded: false,
            isReportUploadedError: false
        }
    }
    capitalizeWords = (arr) => {
        return arr.map(element => {
          return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
        });
    }
    showModal = () => {
        this.setState({ showTopAdmin: true })
    }
    closeModal = () => {
        this.setState({ showTopAdmin: false })
    }
    showDataTransferModal = () => {
        this.setState({ showDataTransfer: true })
    }
    closeDataTransferModal = () => {
        this.setState({ showDataTransfer: false })
    }
    exportMonthlyReport = () => {

        API.post('react_admin/export_monthly_report').then(res => {
            console.log(res);
            if(res.data.status == "ok"){
                this.setState({ isReportUploaded: true })
                this.setState({ isReportUploadedError: false })
               this.resetMontlyReport();
            }
            else{
                this.setState({ isReportUploadedError: true })
                this.setState({ isReportUploaded: false })
                //render(Message, document.body);
                this.resetMontlyReport();
            }
        })
        .catch((error)=>{
            this.setState({ isReportUploadedError: true })
            this.setState({ isReportUploaded: false })
            this.resetMontlyReport();
        })
    }
    resetMontlyReport(){
        setTimeout(()=>{ this.setState({ isReportUploaded: false })
        this.setState({ isReportUploadedError: false })},5000)
    }
    render() {
        return (
            <div>
                <Navbar>
                    <Nav className="admin-nav-bar" activeKey={this.state.activeKey}>
                        <NavDropdown  className={this.state.activeKey == 'users' ? 'active' : ''} eventkey='users' title={i18n.t('admin.nav_menu.user.title')} id="basic-nav-dropdown" >
                            <NavDropdown.Item  href={`${this.props.match.url}/users/free`}>{i18n.t('admin.nav_menu.user.sub_menu.free')}</NavDropdown.Item>
                            <NavDropdown.Item  href={`${this.props.match.url}/users/paid`}>{i18n.t('admin.nav_menu.user.sub_menu.individual')}</NavDropdown.Item>
                            <NavDropdown.Item  href={`${this.props.match.url}/institutions/paid`}>{i18n.t('admin.nav_menu.user.sub_menu.teams')}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item  href={`${this.props.match.url}/users/pack`}>{i18n.t('admin.nav_menu.user.sub_menu.pack_user')}</NavDropdown.Item>
                            <NavDropdown.Item  href={`${this.props.match.url}/institutions/pack`}>{i18n.t('admin.nav_menu.user.sub_menu.pack_teams')}</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link eventKey= 'distributors' href={`${this.props.match.url}/distributors`}>{i18n.t('admin.nav_menu.distributor.title')}</Nav.Link>
                        <NavDropdown className={this.state.activeKey == 'invitations' ? 'active' : ''} eventkey='invitations' title={i18n.t('admin.nav_menu.invitation.title')} id="basic-nav-dropdown">
                            <NavDropdown.Item  href={`${this.props.match.url}/invitations/user`}>{i18n.t('admin.nav_menu.user.title')}</NavDropdown.Item>
                            <NavDropdown.Item  href={`${this.props.match.url}/invitations/institute`}>{i18n.t('admin.nav_menu.user.sub_menu.teams')}</NavDropdown.Item>
                            <NavDropdown.Item  href={`${this.props.match.url}/invitations/distributor`}>{i18n.t('admin.nav_menu.distributor.title')}</NavDropdown.Item>
                            <NavDropdown.Item  href={`${this.props.match.url}/invitations/reseller`}>{i18n.t('admin.nav_menu.invitation.sub_menu.reseller')}</NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link href={`${this.props.match.url}/invitations`}>Invitations</Nav.Link> */}
                        <Nav.Link eventKey='payments' href={`${this.props.match.url}/payments`}>{i18n.t('admin.nav_menu.payment.title')}</Nav.Link>
                        <Nav.Link eventKey='vouchers' href={`${this.props.match.url}/vouchers`}>{i18n.t('admin.nav_menu.voucher.title')}</Nav.Link>
                        <NavDropdown className={this.state.activeKey == 'settings' ? 'active' : ''} eventkey= 'settings' title={i18n.t('admin.nav_menu.setting.title')} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={this.showModal}>{i18n.t('admin.nav_menu.setting.sub_menu.support_email')}</NavDropdown.Item>
                            <NavDropdown.Item href={`${this.props.match.url}/subscription_reminders`}>{i18n.t('admin.nav_menu.setting.sub_menu.reminders')}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            {/* <NavDropdown.Item onClick={this.showDataTransferModal}>{i18n.t('admin.nav_menu.setting.sub_menu.data_transfer_group')}</NavDropdown.Item>
                            <NavDropdown.Divider /> */}
                            <NavDropdown.Item href={`${this.props.match.url}/manage_pages`}>{i18n.t('admin.nav_menu.setting.sub_menu.page')}</NavDropdown.Item>
                            <NavDropdown.Item href={`${this.props.match.url}/keyboards`}>{i18n.t('admin.nav_menu.setting.sub_menu.keyboards')}</NavDropdown.Item>
                            <NavDropdown.Item href={`${this.props.match.url}/preferences`}>{i18n.t('admin.nav_menu.setting.sub_menu.preferences')}</NavDropdown.Item>
                            {/* <NavDropdown.Divider />
                            <NavDropdown.Item href={`${this.props.match.url}/delayed_jobs/enqueued`}>Delayed job</NavDropdown.Item> */}
                            <NavDropdown.Item href={`${this.props.match.url}/oauth`}>{i18n.t('admin.nav_menu.setting.sub_menu.oauth')}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href={`${this.props.match.url}/libraries`}>{i18n.t('admin.nav_menu.setting.sub_menu.libraries')}</NavDropdown.Item>
                            <NavDropdown.Item href={`${this.props.match.url}/activelearning`}>{i18n.t('admin.nav_menu.setting.sub_menu.activelearning')}</NavDropdown.Item>
                            <NavDropdown.Item href={`${this.props.match.url}/educationallinks`}>{i18n.t('admin.nav_menu.setting.sub_menu.educationallinks')}</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={this.exportMonthlyReport}>{i18n.t('admin.nav_menu.setting.sub_menu.export_monthly_report')}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar>
                {
                    this.state.showTopAdmin ? <TopAdminModal showTopAdmin={this.state.showTopAdmin} closeModal={this.closeModal} top_admin_email = {this.state.top_admin_email}></TopAdminModal> : null
                   
                }
                {
                     this.state.showDataTransfer ? <DataTransferModal showTopAdmin={this.state.showDataTransfer} closeModal={this.closeDataTransferModal} top_admin_email = {this.state.top_admin_email}></DataTransferModal> : null
                }
                {
                    this.state.isReportUploaded ? <FlashMessage duration={5000}>
                    <div className="report-sucess-message">
                        <strong>
                        {i18n.t('admin.nav_menu.setting.export_monthly_report_notification.sucess_notification')}
                        </strong>
                    </div>
                    </FlashMessage> : null
                }
                {
                    this.state.isReportUploadedError ? <FlashMessage duration={5000} >
                    <div className="report-failure-message">
                        <strong>
                        {i18n.t('admin.nav_menu.setting.export_monthly_report_notification.failure_notification')}
                        </strong>
                    </div>
                    </FlashMessage> : null
                }
            </div>
        )
    }

}

export default withTranslation() (NavMenu)